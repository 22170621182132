.scf-path-create-main-wrapper {
  a {
    color: var(--main-dark-orange);

    &:hover {
      color: var(--main-darker-orange);
    }
  }

  .testurl-wrap {
    position: relative;
    width: 100%;

    input {
      padding: 0 168px 0 16px;
    }

    .testurl {
      color: #fff;
      font-size: 16px;
      letter-spacing: -0.5px;
      width: 152px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      background: var(--main-dark-orange);
      cursor: pointer;
      border-radius: 0 8px 8px 0;
      padding: 12px 0;

      .lds-ring {
        margin: 0 4px 0 0;
        top: 3px !important;
        right: 0 !important;

        div {
          margin: 0;
          border-color: rgba(25, 25, 25, 0.4) transparent transparent transparent;
        }
      }

      &:hover {
        background: var(--main-darker-orange);
      }

      &.testurl-disable {
        background: rgba(25, 25, 25, 0.1);
        color: rgba(25, 25, 25, 0.4);
      }
    }
  }

  .response-api-bubble {
    border-radius: 16px 0 16px 16px;
    padding: 10px;
    background: #FFF2E5;
    border: solid 1px rgba(25, 25, 25, 0.1);
    max-width: 70%;
    display: inline-block;
    color: rgba(25, 25, 25, 0.6);
    font-size: 16px;
    letter-spacing: -0.3px;
    text-align: left;

    .faq-caption {
      margin: 0;
      max-width: 280px;
      word-break: break-word;
    }

    .message-info {
      margin: 4px 0 0;
      font-size: 10px;
      text-align: right;

      img {
        top: 1px !important;
      }
    }

    .faq-image-video {
      position: relative;
      border-radius: 16px 0 16px 16px;

      .message-info {
        background: rgba(25, 25, 25, 0.4);
        position: absolute;
        right: 8px;
        bottom: 8px;
        font-size: 12px;
        border-radius: 16px;
        color: #fff !important;
        padding: 2px 8px;

        img {
          display: inline-block;
          width: unset !important;
        }
      }

      .black-layer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 48px;
        background: rgba(25, 25, 25, 0.4);
        border-radius: 50%;

        svg {
          font-size: 30px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        display: block;
        border-radius: 16px 0 16px 16px;
      }

      video {
        max-width: 100%;
        max-height: 100%;
        display: block;
        width: 100%;
        border-radius: 16px 0 16px 16px;
      }

      p {
        margin: 8px 0 0;
      }
    }

    .message-info-caption {
      margin: 4px 0 0 !important;
      font-size: 10px !important;
      text-align: right;
      position: relative !important;
      right: unset !important;
      bottom: unset !important;
      color: rgba(25, 25, 25, 0.6) !important;

      img {
        top: 1px !important;
      }
    }

    .faq-document {
      .faq-document-outer {
        padding: 0 0 0 54px;
        position: relative;
      }

      .faq-document-inner {
        background: var(--main-dark-orange);
        width: 48px;
        height: 48px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 0;

        svg {
          color: #fff;
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .faq-filename {
        color: #191919;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        height: 48px;
        font-size: 14px;
        letter-spacing: -0.4px;
        padding: 14px 0 0;
      }
    }
  }

  .label-left {
    padding-left: 36px;
    position: relative;
  }

  .custom-switch-wrapper {
    display: inline-block !important;
    width: 30px !important;
    height: 16px;
    position: absolute !important;
    top: 2px;
    left: 0;
    margin: 0;

    .custom-switch {
      padding: 0;
    }

    .custom-switch.round {
        &::before {
            width: 10px;
            height: 10px;
            bottom: 3px;
            left: 4px;
        }
    }

    input:checked + .custom-switch:before {
        transform: translateX(11px);
    }
  }

  .integration-list-loading-wrapper {
    height: 80vh;

    .lds-ring {
      width: 50px;
      height: 50px;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;

      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }

  .scf-path-create-header {
    padding-bottom: 24px;
    margin-bottom: 12px;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    position: relative;
    display: block;

    .grey-font {
      font-size: 16px;
      letter-spacing: -0.6px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 180px;
    }
  }

  .scf-path-create-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    margin: 0;
    color: #191919;
}

  .scf-path-create-description {
    margin: 8px 0 16px;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: calc(100% - 230px);
    color: #191919;
    line-height: 24px;
  } 

  .scf-path-create-search {
    width: 300px;
    border-radius: 8px;
    border: solid 1px rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;

    &.focus-search {
      border: solid 1px #ff7e00;
      
      svg {
        color: #ff7e00;
      }
    }

    span {
      position: relative;
      top: -2px;
    }

    input {
      border: none;
      background: transparent;
      padding: 8px 4px 8px 16px;
      width: 90%;
    }
  }
}

.faq-list thead {
  tr {
    background: transparent;

    td {
      padding: 8px;
      // width: calc((100% - 100px) / 5);
      font-size: 14px;
      word-wrap: break-word;
      white-space: normal;

      
      &.col_type {
        width: 105px;
      }

      &.col_action {
        width: 105px;
      }

      &:last-child {
        width: 90px;

        .custom-select-option-value-wrapper {
          height: 30px;
          padding: 0 4px 0 15px !important;

          .custom-select-option-value {
            padding: 0;
          }

          svg {
            right: 10px;
          }
        }
      }

    }
  }
}

.faq-list {
  tbody {
    tr {
      // cursor: pointer;

      &:hover {
        // background-color: #FFF2E5;

        td {
          // color: #FF7E00;
        }
      }

      &.no-hover {
        cursor: default !important;
        &:hover {
          background-color: #ffffff !important;
    
          td {
            color: #191919 !important;
          }
        } 
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;

        &.col_id {
          width: 70px;
        }
        
        &.col_type {
          width: 105px;
        }

        &.col_action {
          width: 105px;
        }

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(1) {
          p {
            margin-bottom: 4px;
          }

          .col_name_alias {
            font-size: 12px;
          }
        }

        &:nth-child(4) {
          &.col-origin {
            p {
              margin: 0;
              display: inline;
            }

            img {
              position: relative;
              top: -2px;
              margin-right: 8px; 
              width: 20px; 
            }
          }
        }
      }
    }
  }
}

// .faq-list button svg {
  // position: relative !important;
  // right: unset !important;
  // top: -2px !important;
  // margin-right: 5px;
// }

.faq-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  /* display: none; */
}

.faq-list td {
  max-width: unset !important;

  svg {
    position: relative !important;
    // top: unset !important;
    right: unset !important;
  }
}

.create-path-add-wrapper {
  .create-path-add-title {
    font-size: 20px;
    letter-spacing: -0.6px;
    line-height: 1;
  }

  .close-modal-create-path {
    position: absolute;
    font-size: 22px;
    right: 0;
    top: 2px;
    cursor: pointer;
  }

  .form-content-wrapper {
    input, textarea, label {
      width: 100%;
      position: relative;

      .clear-all {
        color: var(--watermelon);
        cursor: pointer;
        position: absolute;
        right: 0;
        bottom: 0;

        &:hover {
          color: var(--watermelon-dark);
        }
      }
    }

    .input-counter {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .button-create-path {
    width: 100%;
    margin-top: 20px;
  }
  
  .create-path-add-type-wrapper {
    position: relative;
    width: 100%;
    display: block;

    .create-path-add-type-select {
      display: inline-block;
      border-radius: 8px;
      width: calc((100% / 3) - 4px);
      margin-right: 6px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: center;
      padding: 12px 5px;
      font-size: 16px;
      letter-spacing: -0.3px;
      max-width: 204px;

      &.disabled-type-select {
        color: rgba(25, 25, 25, 0.4) !important;
        border: solid 1px rgba(25, 25, 25, 0.4) !important;

        svg {
          color: rgba(25, 25, 25, 0.4) !important;
        }
      }

      &.create-path-add-type-active {
        border: solid 1px #ff7d00;
        color: #ff8d00;

        svg {
          color: #ff7d00;
        }
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4);
      }

      &:nth-child(3) {
        margin: 0;
      }

      &:hover {
        border: solid 1px #ff7d00;
        color: #ff8d00;

        svg {
          color: #ff7d00;
        }
      }
    }
  }

  .choose-related-department-wrapper {
    position: relative;
    width: 100%;
    display: block;

    .choose-related-department-select {
      display: inline-block;
      border-radius: 8px;
      width: calc((100% / 3) - 10px);
      margin-right: 15px;
      border: solid 1px #dcdcdc;
      cursor: pointer;
      text-align: left;
      padding: 12px 13px;
      font-size: 16px;
      letter-spacing: -0.3px;
      margin-bottom: 10px;

      label {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -1px;
        color: rgba(25, 25, 25, 0.4);
      }

      &:nth-child(3) {
        margin: 0;
      }

      &:hover {
        border: solid 1px #ff7d00;
        
        label {
          color: #ff7d00;
        }
      }

      label {
        margin: 0;
      }

      &.choose-related-department-select-active {
        border: solid 1px #ff7d00;
        
        label {
          color: #ff7d00;
        }
      }
    }
  }
}