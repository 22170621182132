.utm-wrapper {
    // min-height: 500px;
    .group-by-wrapper {
        b { 
            font-size: 14px;
            letter-spacing: -0.4px;
        }

        .checkbox-wrapper {
            width: 344px;
            border-radius: 8px;
            border: solid 1px #dcdcdc;
            font-size: 14px;
            margin: 8px 0 12px;
            letter-spacing: -0.2px;

            label {
                margin: 0;
                line-height: 21px;
            }

            .custom-checkbox {
                width: 100%;
                padding: 8px 12px;
                height: 40px;
            }
    
            .custom-checkbox-stripe {
                width: 100%;
                padding: 9px 12px;
                height: 40px;
            }

            .custom-checkbox-stripe [type="checkbox"]:checked + label:after {
                width: 16px;
                height: 16px;
                top: 2px;
                left: 0;
              }
    
            .select-all-group {
                border-bottom: solid 1px #dcdcdc;
            }
        }
    }


    .utm-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        color: #191919;
        padding: 8px 0 12px;
        border-bottom: solid 1px #dcdcdc;
    }

    .utm-description {
        margin: 8px 0 16px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: calc(100% - 230px);
        color: #191919;
        line-height: 24px;
    }

    .add-utm-button {
        // height: 40px;
        // line-height: 23px;
        // padding: 0;
        // width: 195px;
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;

        // svg {
        //     vertical-align: top;
        //     font-size: 24px;
        //     position: relative;
        //     top: -1px;
        //     margin-right: 4px;
        // }
    }

    .utm-data-outer-wrapper {
        // padding: 12px 0 0;
    } 

    .utm-data-wrapper {
        width: 100%;
        color: #191919;
        overflow-x: auto;

        .no-utm {
            width: 100%;
            text-align: center;
            font-size: 20px;
            letter-spacing: -0.6px;
            color: rgba(25, 25, 25, 0.6);
        }

        table {
            width: 1480px;

            thead {
                font-size: 14px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    // padding: 0 8px 8px;
                    padding: 8px 20px 8px 8px !important;
                    white-space: nowrap;
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                tr {
                    border-bottom: solid 1px #dcdcdc;
                }

                td {
                    padding: 16px 8px;

                    &.col_action {
                        font-size: 14px;
                        letter-spacing: -0.4px;

                        .edit-button {
                            color: #ff7d00;
                            cursor: pointer;
                            margin-right: 16px;
                        }

                        .remove-button {
                            color: #ff3f57;
                            cursor: pointer;
                        }

                        svg {
                            font-size: 16px;
                            position: relative;
                            top: -2px;
                            margin-right: 4px;
                        }
                    }
                }
            }
        }
    }
}