.info-box-hover {
    .tooltip-inner {
        color: #fff !important;
        background: #6B6B6B !important;
        font-size: 12px;
        padding: 8px;
        width: 153px;
    }

    .arrow {
        &::before {
            border-bottom-color: #6b6b6b !important;
            border-top-color: #6b6b6b !important;
        }
    }
}

.message-history-report-wrapper {
    min-width: 600px;

    .message-history-report-title {
        font-size: 20px;
        letter-spacing: -0.6px;
        margin: 0 0 16px 0;
        border-bottom: solid 1px #dcdcdc;
        color: #191919;
        padding: 8px 0 12px;
    }

    .download-report-button {
        text-align: center;
        position: absolute;
        top: 32px;
        right: 32px;
    }

    .message-history-report-data-outer-wrapper {

        .advanced-date-selection {
            display: inline-block;
        }
    } 

    .message-history-report-data-wrapper {
        width: 100%;
        color: #191919;
        overflow-x: auto;

        .no-message-history-report {
            width: 100%;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.5px;
            color: rgba(25, 25, 25, 0.6);

            p {
                margin: 0;
            }
        }

        table {
            min-width: 100%;
            width: 1020px;

            thead {
                font-size: 14px;
                border-bottom: solid 1px #dcdcdc;
                color: #191919;
                letter-spacing: -0.4px;

                td {
                    padding: 0 8px 8px;
                    white-space: nowrap;
                }
            }

            tbody {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: #191919;

                td {
                    padding: 16px 8px;
                    vertical-align: top;
                }
            }
            
            td {
                word-wrap: break-word;
                
                &.with-max-width {
                    white-space: break-spaces;
                    max-width: 130px;
                }
            }

            img {
                width: 16px;
                height: 16px;
                margin-right: 4px;
                transform: translateY(-2px);
            }

            .col_time, .col_name, .col_phone {
                display: inline-block;
                width: 140px;
            }

            .col_alias {
                display: inline-block;
                width: 100px;
            }

            .col_channel {
                display: inline-block;
                width: 180px;
            }

            .col_message {
                display: inline-block;
                width: 95px;
                cursor: pointer;

                p {
                    display: inline-block;
                    margin: 0;
                    font-family: PTRootBold;
                    color: #FF7E00;
                    letter-spacing: -0.4px;
                    line-height: 21px;
                }
            }

            .col_type {
                display: inline-block;
                width: 95px;
                text-transform: capitalize;
            }

            .col_status {
                display: inline-block;
                width: 130px;
                
                .label {
                    display: inline-block;
                    margin: 0;
                    padding: 2px 8px;
                    font-size: 12px;
                    font-family: PTRootBold;
                    line-height: 18px;
                    letter-spacing: -0.4px;
                    border-radius: 4px;
                    color: white;
                    position: relative;

                    .info-box-hover {
                        padding: 8px;
                        font-family: PTRootUI !important;
                        font-size: 12px;
                        color: #fff;
                        letter-spacing: -0.2px;
                        background: rgba(107, 107, 107, 1);
                        border-radius: 4px;
                        position: absolute;
                        width: 142px;
                        right: -8px;
                        top: 30px;
                        display: none;

                        &::before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            background: rgba(107, 107, 107, 1);
                            position: absolute;
                            right: 16px;
                            top: -5px;
                            transform: rotate(45deg);
                        }
                    }

                    svg {
                        color: #fff;
                        margin-left: 4px;
                        position: relative;
                        top: -1px;

                        &:focus {
                            outline: none !important;
                        }

                        // &:hover {
                        //     & ~ .info-box-hover {
                        //         display: inline-block;
                        //     }
                        // }
                    }
                }
                
                .pending {
                    background: #FF7E00;
                }
                
                .failed {
                    background: #FF3F57;
                }
                
                .sent {
                    background: #19C700;
                }
                
                .acknowledged {
                    background: #458500;
                }
                
                .delivered {
                    background: #025F2D;
                }
                
                .read {
                    background: #3BAEFF;
                }
            }
        }
    }
}
